import React from 'react';
import {Link} from 'gatsby';
import Layout from '../layout';
import phone from '../../assets/phone.svg';
import mail from '../../assets/mail.svg';
import linkedin from '../../assets/linkedin.png';

const VALUE_IS_NONE = "n/a";

export default function Profile({ profile, contactIsPublic }) {
  const { position, linkedinUrl, email, mobile, fullName, photoName, description, id } = profile ?? {};
  var desc = description?.replace(/(?:\r\n|\r|\n)/g, '<br>');

  return (
    <Layout
      title={fullName}
      metaDescription={desc}
    >
      <section className='profile page-section--profile row'>
        <nav className='col-xs-12 col-lg-12 profile__breadcrumb'>
          <ul>
            <li><Link to='/meet-the-team'>Meet the Team</Link></li>
            {contactIsPublic === true &&
              <li>External data</li>
            }
            {fullName &&
              <li>{fullName}</li>
            }
          </ul>
        </nav>
        <div className='col-xs-12 col-md-4 col-lg-4'>
          <img src={`/images/resized/full_${photoName}`} alt={`${fullName}`} className='profile__img img-fluid' />
        </div>
        <div className='col-xs-12 col-md-7 col-lg-7'>
          <div className='profile__content'>
            <div className='profile__title'>
              {fullName &&
                <h1>{fullName}</h1>
              }
              {position &&
                <h3>{position}</h3>
              }
            </div>
            <p dangerouslySetInnerHTML={{ __html: desc }} />
            {contactIsPublic !== false &&
              <div className='profile__links'>
                {email !== VALUE_IS_NONE &&
                  <a className='profile__link profile__link--mail' href={"mailto:" + email}>
                    <img alt={`Send an email to ${fullName}`} src={mail} />
                    <span>{email}</span>
                  </a>
                }
                {mobile !== VALUE_IS_NONE && id !== "businessDevMan" &&
                  <a className='profile__link profile__link--tel' href={"tel:" + mobile}>
                    <img alt={`Call ${fullName}`} src={phone} />
                    <span>{mobile}</span>
                  </a>
                }
                {linkedinUrl !== VALUE_IS_NONE &&
                  <a className='profile__link profile__link--li' href={linkedinUrl}>
                    <span>Follow me on <img src={linkedin} alt={`Check ${fullName}'s Linkedin profile`} /></span> <span>{fullName}</span>
                  </a>
                }
              </div>
            }
          </div>
        </div>
      </section>
    </Layout>
  );
}
